
import { defineComponent, PropType, ref } from "vue";
import { TodoProps } from "../todo/Todo.vue";
import { v4 as uuid } from "uuid";

export default defineComponent({
  name: "AddTodoForm",
  props: {
    todos: {
      type: Array as PropType<TodoProps[]>,
      required: true,
    },
  },
  setup() {
    const newTodo = ref("");

    return { newTodo };
  },
  methods: {
    addTodo() {
      if (this.newTodo) {
        const newTodos: TodoProps[] = [
          ...this.$props.todos,
          {
            date: Date.now(),
            id: uuid(),
            isFinished: false,
            value: this.newTodo,
          },
        ];

        this.newTodo = "";

        this.$emit("addTodo", newTodos);
      }
    },
  },
});

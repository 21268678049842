
import { defineComponent } from "vue";

export const sortByOptions: { text: string; value: string }[] = [
  {
    text: "Most recent",
    value: "most-recent",
  },
  {
    text: "Alphabetically",
    value: "alphabetically",
  },
  {
    text: "Finished",
    value: "finished",
  },
];

export default defineComponent({
  name: "Filters",
  props: {
    allTodosAreFinished: {
      type: Boolean,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      options: sortByOptions,
    };
  },
  emits: ["sort", "toggleTodosAreFinished"],
  methods: {
    onSort(event: { target: HTMLSelectElement }) {
      this.$emit("sort", event.target.value);
    },
    toggleTodosAreFinished() {
      this.$emit("toggleTodosAreFinished");
    },
  },
  computed: {
    buttonText(): string {
      return `Mark all as ${this.allTodosAreFinished ? "un" : ""}finished`;
    },
  },
});


import { defineComponent, PropType } from "vue";
import Todo, { TodoProps } from "../todo/Todo.vue";

export default defineComponent({
  name: "TodoList",
  components: {
    Todo,
  },
  props: {
    sortBy: {
      type: String,
      required: true,
    },
    todos: {
      type: Array as PropType<TodoProps[]>,
      required: true,
    },
  },
  methods: {
    removeTodo(id: string) {
      const index = this.getTodoIndex(id);

      const newTodos = [
        ...this.$props.todos.slice(0, index),
        ...this.$props.todos.slice(index + 1),
      ];

      this.$emit("saveTodos", newTodos);
    },
    saveEditedTodo(value: string, id: string) {
      const index = this.getTodoIndex(id);

      const newTodos = [
        ...this.$props.todos.slice(0, index),
        {
          ...this.$props.todos[index],
          isEditedOn: Date.now(),
          value,
        },
        ...this.$props.todos.slice(index + 1),
      ];

      this.$emit("saveTodos", newTodos);
    },
    toggleTodoIsFinished(id: string) {
      const index = this.getTodoIndex(id);

      const newTodos = [
        ...this.$props.todos.slice(0, index),
        {
          ...this.$props.todos[index],
          isFinished: !this.$props.todos[index].isFinished,
        },
        ...this.$props.todos.slice(index + 1),
      ];

      this.$emit("saveTodos", newTodos);
    },
    getTodoIndex(id: string): number {
      return this.$props.todos.findIndex((todo) => todo.id === id);
    },
  },
});


import { defineComponent, nextTick, ref } from "vue";
import dayjs from "dayjs";

export interface TodoProps {
  date: number;
  id: string;
  isEditedOn?: number;
  isFinished: boolean;
  value: string;
}

export default defineComponent({
  name: "Todo",
  data: function () {
    return {
      isBeingEdited: false,
    };
  },
  props: {
    date: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    isEditedOn: {
      type: Number,
      default: undefined,
    },
    isFinished: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ["remove", "saveEdit", "toggleIsFinished"],
  setup() {
    const inputRef = ref<HTMLInputElement>();

    return { inputRef };
  },
  methods: {
    remove() {
      this.$emit("remove", this.id);
    },
    toggleIsFinished() {
      this.$emit("toggleIsFinished", this.id);
    },
    async startEdit() {
      this.isBeingEdited = true;
      await nextTick();
      this.inputRef?.focus();
    },
    cancelEdit() {
      this.isBeingEdited = false;
    },
    saveEdit(event: { target: HTMLInputElement }) {
      this.$emit("saveEdit", event.target.value, this.id);
      this.isBeingEdited = false;
    },
  },
  computed: {
    formattedDate(): string {
      return this.isEditedOn
        ? `Last edited on ${dayjs(this.isEditedOn).format("HH:mm, DD/MM/YY")}`
        : `Added on ${dayjs(this.date).format("HH:mm, DD/MM/YY")}`;
    },
  },
});
